const routes = {
    review: {
        href: '/review',
        text: 'Review / Assess',
        icon: 'cogs',
        skipNavigation: true,
        skipBreadcrumb: false,
    },
    review_assessments: {
        href: '/review/assessments',
        text: 'Live Assessments',
        icon: 'cubes',
    },
    review_completed_client_assessments: {
        href: '/review/completed-client-assessments',
        text: 'Completed Assessments',
        icon: 'cubes',
    },
    review_client_assessments: {
        href: '/review/client-assessments',
        text: 'Live Assessments',
        icon: 'cubes',
    },
    review_assessment_comments: {
        href: '/review/assessment-comments',
        text: 'Assessment Comments',
        icon: 'cubes',
    },
    review_purchased_assessments: {
        href: '/review/purchased-assessments',
        text: 'Purchased Assessments',
        icon: 'cubes',
    },
    review_refunded_assessments: {
        href: '/review/refunded-assessments',
        text: 'Refunded Assessments',
        icon: 'cubes',
    },
    review_completed_assessments: {
        href: '/review/completed-assessments',
        text: 'Completed Assessments',
        icon: 'cubes',
    },
    review_expiring_assessments: {
        href: '/review/expiring-assessments',
        text: 'Expiring Assessments',
        icon: 'cubes',
    },
    review_assessments_view: {
        href: '/review/assessments/:id/view',
        text: '{assessment.review}',
    },
    review_purchased_supplychains: {
        href: '/review/purchased-supplychains',
        text: 'Purchased Supply Chains',
        icon: 'link',
    },
};

export default routes;

import { Button, Menu, MenuItem } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import queryString from 'query-string';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import ResendReceiptDialog from 'components/assessment-types/ResendReceiptDialog';
import { Icon } from 'components/elements';
import { Loading } from 'components/elements/wrappers';
import {
    ASSESSMENT_TYPE_MILESTONES,
    IN_PROGRESS_STATUSES,
    STATUS_COMPLETED,
    STATUS_COMPLETED_EXPIRED,
    STATUS_REFUNDED,
} from 'constants/assessmentTypeMilestones';
import {
    ROLE_ADMINISTRATOR,
    ROLE_ASSESSMENT_ASSESSOR,
    ROLE_ASSESSMENT_REVIEWER,
    ROLE_COMPANY_ADMINISTRATOR,
    ROLE_PROSURE_CLIENT_SERVICES,
    ROLE_REGISTRATIONS_RENEWALS,
} from 'constants/role';
import { canNavigateWithRestriction, isCompanyAdmin } from 'helpers/helpers';
import { REFUND_REASON_OPTIONS } from 'interface/Payment/RefundPayment';
import { HTTP, InstanceService, Response } from 'service';
import { translate } from 'service/Translator';
import AssessmentTypeInstanceTable from './AssessmentTypeInstanceTable';

class AssessmentTypeInstanceList extends Component {
    constructor(props) {
        super(props);

        this.inProgressColumns = [
            {
                name: 'Badge',
                property: 'file',
                type: 'image',
                sortable: false,
                grow: 2,
            },
            {
                name: 'Assessment Type',
                property: '_links.assessment-type.name',
                sortable: false,
                grow: 4,
            },
            {
                name: 'Purchased By',
                type: 'string',
                property: 'purchasedBy',
                sortable: false,
                grow: 3,
            },
            {
                name: 'Purchased Date',
                type: 'datetime',
                property: 'purchasedAt',
                sortable: false,
                grow: 3,
            },
            {
                name: 'Milestone',
                type: 'mapping',
                mapping: ASSESSMENT_TYPE_MILESTONES,
                property: 'status',
                sortable: false,
                grow: 2,
            },
            {
                name: '',
                type: 'callback',
                grow: 2,
                callback: (row) => {
                    if (
                        this.getCompletedRoute(row) ||
                        Response.getLink(row, 'resend-receipt') ||
                        Response.getLink(row, 'certificate')
                    ) {
                        return (
                            <Popover2
                                className="inline-block"
                                content={this.actionsMenu(row)}
                                placement="bottom"
                            >
                                <Button
                                    alignText="left"
                                    rightIcon="caret-down"
                                    text="Actions"
                                />
                            </Popover2>
                        );
                    } else {
                        return null;
                    }
                },
            },
        ];

        this.allStatusColumns = [
            {
                name: 'Badge',
                property: 'file',
                type: 'image',
                sortable: false,
                grow: 2,
            },
            {
                name: 'Assessment Type',
                property: '_links.assessment-type.name',
                sortable: false,
                grow: 4,
            },
            {
                name: 'Purchased By',
                type: 'string',
                property: 'purchasedBy',
                sortable: false,
                grow: 3,
            },
            {
                name: 'Purchased Date',
                type: 'datetime',
                property: 'purchasedAt',
                sortable: true,
                grow: 3,
            },
            {
                name: 'Completed  Date',
                type: 'datetime',
                property: 'completedAt',
                sortable: false,
                grow: 2,
            },
            {
                name: 'Status',
                type: 'mapping',
                mapping: ASSESSMENT_TYPE_MILESTONES,
                property: 'status',
                sortable: true,
                grow: 2,
            },
            {
                name: '',
                type: 'callback',
                grow: 2,
                callback: (row) => {
                    return (
                        <Popover2
                            className="inline-block"
                            content={this.actionsMenu(row)}
                            placement="bottom"
                        >
                            <Button
                                alignText="left"
                                rightIcon="caret-down"
                                text="Actions"
                            />
                        </Popover2>
                    );
                },
            },
        ];

        this.completedColumns = [
            {
                name: 'Badge',
                property: 'file',
                type: 'image',
                sortable: false,
                grow: 2,
            },
            {
                name: 'Assessment Type',
                property: '_links.assessment-type.name',
                sortable: false,
                grow: 4,
            },
            {
                name: 'Purchased By',
                type: 'string',
                property: 'purchasedBy',
                sortable: false,
                grow: 2,
            },
            {
                name: 'Purchased Date',
                type: 'datetime',
                property: 'purchasedAt',
                sortable: false,
                grow: 2,
            },
            {
                name: 'Completed  Date',
                type: 'datetime',
                property: 'completedAt',
                sortable: false,
                grow: 2,
            },
            {
                name: 'Expiry Date',
                type: 'datetime',
                property: 'expiryDate',
                sortable: false,
                grow: 2,
            },
            {
                name: '',
                type: 'callback',
                grow: 2,
                callback: (row) => {
                    return (
                        <Popover2
                            className="inline-block"
                            content={this.actionsMenu(row)}
                            placement="bottom"
                        >
                            <Button
                                alignText="left"
                                rightIcon="caret-down"
                                text="Actions"
                            />
                        </Popover2>
                    );
                },
            },
        ];

        this.expiredColumns = [
            {
                name: 'Badge',
                property: 'file',
                type: 'image',
                sortable: false,
                grow: 2,
            },
            {
                name: 'Assessment Type',
                property: '_links.assessment-type.name',
                sortable: false,
                grow: 4,
            },
            {
                name: 'Purchased By',
                type: 'string',
                property: 'purchasedBy',
                sortable: false,
                grow: 2,
            },
            {
                name: 'Purchased Date',
                type: 'datetime',
                property: 'purchasedAt',
                sortable: false,
                grow: 2,
            },
            {
                name: 'Completed  Date',
                type: 'datetime',
                property: 'completedAt',
                sortable: false,
                grow: 2,
            },
            {
                name: 'Expiry Date',
                type: 'datetime',
                property: 'expiryDate',
                sortable: false,
                grow: 2,
            },
            {
                name: '',
                type: 'callback',
                grow: 2,
                callback: (row) => {
                    return (
                        <Popover2
                            className="inline-block"
                            content={this.actionsMenu(row)}
                            placement="bottom"
                        >
                            <Button
                                alignText="left"
                                rightIcon="caret-down"
                                text="Actions"
                            />
                        </Popover2>
                    );
                },
            },
        ];

        this.refundColumns = [
            {
                name: 'Badge',
                property: 'file',
                type: 'image',
                sortable: false,
                grow: 2,
            },
            {
                name: 'Assessment Type',
                property: '_links.assessment-type.name',
                sortable: false,
                grow: 4,
            },
            {
                name: 'Purchased By',
                type: 'string',
                property: 'purchasedBy',
                sortable: false,
                grow: 2,
            },
            {
                name: 'Purchased Date',
                type: 'datetime',
                property: 'purchasedAt',
                sortable: false,
                grow: 2,
            },
            {
                name: 'Refund Date',
                type: 'datetime',
                property: 'refundedAt',
                sortable: false,
                grow: 2,
            },
            {
                name: 'Refund Reason',
                type: 'datetime',
                property: 'refundReason',
                sortable: false,
                grow: 2,
            },
            {
                name: 'Refund Details',
                type: 'callback',
                grow: 2,
                callback: (row) => {
                    return translate(row.refundReason, REFUND_REASON_OPTIONS);
                },
            },
        ];

        this.columns = this.inProgressColumns;

        const pathname = props.location.pathname;

        this.defaultFilters = {
            order: pathname.includes('completed')
                ? '-completedAt'
                : '-purchasedAt',
            status: 'in-progress',
            limit: 999,
        };

        this.state = {
            collection: {},
            completedAssessments: [],
            inProgressAssessments: [],
            expiredAssessments: [],
            refundedAssessments: [],
            listTitle: '',
            filter: queryString.parse(props.location.search),
            isLoading: false,
            showResendReceiptDialog: false,
        };

        this.load = this.load.bind(this);
    }

    componentDidUpdate() {
        this.updateListData();
    }

    componentDidMount() {
        this.updateListData();
    }

    resendReceipt = (row) => {
        return (
            <>
                {Response.getLink(row, 'resend-receipt') && (
                    <Button
                        type="button"
                        onClick={() =>
                            this.openResendReceiptDialog(
                                Response.getLink(row, 'resend-receipt')
                            )
                        }
                        intent="primary"
                        className=""
                    >
                        <Icon icon="eye" />
                        Resend Receipt
                    </Button>
                )}
            </>
        );
    };

    closeResendReceiptDialog = () => {
        this.setState({
            ...this.state,
            showResendReceiptDialog: false,
        });
    };

    openResendReceiptDialog = (resendReceiptUrl) => {
        this.setState({
            ...this.state,
            showResendReceiptDialog: true,
            resendReceiptUrl: resendReceiptUrl,
        });
    };

    updateListData = () => {
        const getListTitle = () => {
            const pathname = this.props.location.pathname;

            if (pathname.includes('completed')) {
                return 'Completed';
            } else if (pathname.includes('all-') || pathname.includes('shop')) {
                return 'Assessments';
            } else return 'In-progress';
        };

        const listTitle = getListTitle();
        let newlistData = {};

        switch (listTitle) {
            case 'Assessments':
            case 'Shop':
                newlistData = {
                    listTitle,
                    filters: {},
                    column: this.allStatusColumns,
                };
                break;
            case 'In-Progress':
            case 'Completed':
            default:
                newlistData = {
                    listTitle,
                    status: listTitle.toLowerCase(),
                    column:
                        listTitle === 'Completed'
                            ? this.completedColumns
                            : this.inProgressColumns,
                };
        }

        if (this.state.listTitle !== newlistData.listTitle) {
            this.setState({
                listTitle: newlistData.listTitle,
            });

            this.columns = newlistData.column;

            this.load();
        }
    };

    getRoute = (row) => {
        return canNavigateWithRestriction([
            ROLE_ADMINISTRATOR,
            ROLE_PROSURE_CLIENT_SERVICES,
            ROLE_ASSESSMENT_ASSESSOR,
            ROLE_ASSESSMENT_REVIEWER,
            ROLE_COMPANY_ADMINISTRATOR,
            ROLE_REGISTRATIONS_RENEWALS,
        ])
            ? `/assessments/${row.id}/view`
            : null;
    };

    getCompletedRoute = (row) => {
        const isVisibleToClient =
            Response.getLinkAttribute(
                row,
                'assessment-type',
                'isVisibleToClient'
            ) &&
            ['status_completed', 'status_completed_expired'].includes(
                row.status
            );

        return canNavigateWithRestriction([
            ROLE_ADMINISTRATOR,
            ROLE_PROSURE_CLIENT_SERVICES,
            ROLE_ASSESSMENT_ASSESSOR,
            ROLE_ASSESSMENT_REVIEWER,
            ROLE_REGISTRATIONS_RENEWALS,
        ]) || isVisibleToClient
            ? `${this.props.match.url}/${row.id}/view`
            : canNavigateWithRestriction([ROLE_COMPANY_ADMINISTRATOR])
            ? `/assessments/${row.id}/view`
            : null;
    };

    actionsMenu = (row) => {
        return (
            <Menu>
                {this.getCompletedRoute(row) && (
                    <MenuItem
                        intent="primary"
                        text="View Questions"
                        icon={<Icon className="mt-1" icon="pencil" />}
                        onClick={() =>
                            this.props.history.push(this.getCompletedRoute(row))
                        }
                    />
                )}
                {Response.getLink(row, 'resend-receipt') && (
                    <MenuItem
                        intent="primary"
                        text="Resend Receipt"
                        icon={<Icon className="mt-1" icon="pencil" />}
                        onClick={() =>
                            this.openResendReceiptDialog(
                                Response.getLink(row, 'resend-receipt')
                            )
                        }
                    />
                )}
                {Response.getLink(row, 'certificate') && (
                    <MenuItem
                        intent="primary"
                        text="View Certificate"
                        icon={<Icon className="mt-1" icon="pencil" />}
                        onClick={() =>
                            HTTP.stream(Response.getLink(row, 'certificate'))
                        }
                    />
                )}
            </Menu>
        );
    };

    handleResendReceipt = (event, form) => {
        event.preventDefault();
        const result = InstanceService.resendReceipt(
            this.state.resendReceiptUrl,
            form.email
        );

        if (result) toast.success(`Emails sent to ${form.email}`);
    };

    render() {
        const {
            completedAssessments,
            inProgressAssessments,
            expiredAssessments,
            refundedAssessments,
            filter,
            isLoading,
            showResendReceiptDialog,
        } = this.state;

        const { location } = this.props;
        const isCompanyPage = location.pathname.includes(
            'company/all-assessments'
        );

        const tableHeaderLink = isCompanyPage && isCompanyAdmin && (
            <p class="mb-3">
                To purchase an assessment, go to the 'Shop' area, or
                <Link to={`/shop`}> click here</Link>
            </p>
        );

        return (
            <div className="AssessmentTypesList">
                {showResendReceiptDialog && (
                    <ResendReceiptDialog
                        onClose={this.closeResendReceiptDialog}
                        isDialogOpen={showResendReceiptDialog}
                        onSubmithandler={(event, form) =>
                            this.handleResendReceipt(event, form)
                        }
                    ></ResendReceiptDialog>
                )}

                <Loading isLoading={isLoading}>
                    <AssessmentTypeInstanceTable
                        headerContent={tableHeaderLink}
                        title="In-Progress Assessments"
                        isLoading={isLoading}
                        count={inProgressAssessments?.length}
                        onReload={this.load}
                        data={inProgressAssessments}
                        columns={this.inProgressColumns}
                        onSort={this.load}
                        ordering={filter.order}
                    />

                    <AssessmentTypeInstanceTable
                        title="Completed and Current Assessments"
                        isLoading={isLoading}
                        count={completedAssessments?.length}
                        onReload={this.load}
                        data={completedAssessments}
                        columns={this.completedColumns}
                        onSort={this.load}
                        ordering={filter.order}
                    />

                    <AssessmentTypeInstanceTable
                        title="Completed, but Expired Assessments"
                        isLoading={isLoading}
                        count={expiredAssessments?.length}
                        onReload={this.load}
                        data={expiredAssessments}
                        columns={this.expiredColumns}
                        onSort={this.load}
                        ordering={filter.order}
                    />

                    <AssessmentTypeInstanceTable
                        title="Refunded Assessments"
                        isLoading={isLoading}
                        count={refundedAssessments?.length}
                        onReload={this.load}
                        data={refundedAssessments}
                        columns={this.expiredColumns}
                        onSort={this.load}
                        ordering={filter.order}
                    />
                </Loading>
            </div>
        );
    }

    load(params = {}) {
        if (this.state.isLoading) {
            return;
        }

        const filterParams = Object.assign(
            {},
            this.defaultFilters,
            this.props.filter,
            this.state.filter,
            params
        );

        delete filterParams.status;

        let queryStringFilterParams = queryString.stringify(filterParams);
        if (
            this.props.location.search &&
            '?' + queryStringFilterParams !== this.props.location.search
        ) {
            this.props.history.push({ search: queryStringFilterParams });
        }

        this.setState({ isLoading: true, filter: filterParams });

        HTTP.get('/assessment-type-instances', filterParams).then(
            (response) => {
                if (response) {
                    const { data } = response;
                    const inProgressAssessments = [];
                    const completedAssessments = [];
                    const expiredAssessments = [];
                    const refundedAssessments = [];

                    if (Array.isArray(data.assessmentTypeInstances)) {
                        data.assessmentTypeInstances.forEach(
                            (assessmentType) => {
                                if (
                                    assessmentType.status ===
                                    STATUS_COMPLETED.value
                                ) {
                                    completedAssessments.push(assessmentType);
                                } else if (
                                    assessmentType.status ===
                                    STATUS_COMPLETED_EXPIRED.value
                                ) {
                                    expiredAssessments.push(assessmentType);
                                } else if (
                                    assessmentType.status ===
                                    STATUS_REFUNDED.value
                                ) {
                                    refundedAssessments.push(assessmentType);
                                } else if (
                                    IN_PROGRESS_STATUSES.includes(
                                        assessmentType.status
                                    )
                                ) {
                                    inProgressAssessments.push(assessmentType);
                                }
                            }
                        );
                    }

                    this.setState({
                        isLoading: false,
                        collection: response.data,
                        completedAssessments,
                        inProgressAssessments,
                        expiredAssessments,
                        refundedAssessments,
                    });

                    return true;
                }

                toast.error('Unable to fetch Assessment Type Instances');
                this.setState({ isLoading: false });

                return false;
            }
        );
    }
}

export default AssessmentTypeInstanceList;

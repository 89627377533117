import {
    CLIENT_ROLES,
    CLIENT_ROLE_OPTIONS,
    ROLE_ADMINISTRATOR,
    ROLE_CLIENT_USER,
    ROLE_COMPANY_ADMINISTRATOR,
    ROLE_OPTIONS_WITHOUT_CLIENT,
} from 'constants/role';
import { STATUS_ACTIVE, USER_STATUS_TRANSLATION } from 'constants/user';
import { Component } from 'react';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';

import { Button, FormGroup, H1, InputGroup } from '@blueprintjs/core';
import { HTMLSelect, Icon } from 'components/elements';
import PasswordField from 'components/elements/PasswordField';
import { Loading } from 'components/elements/wrappers';
import { canNavigateWithRestriction } from 'helpers/helpers';
import { HTTP, Response, Routing, StateHandler } from 'service';

class UserAddEdit extends Component {
    constructor(props) {
        super(props);

        this.state = {
            form: {
                forename: '',
                surname: '',
                email: '',
                phone: '',
                jobTitle: '',
                password: '',
                roles: !props.clientResponse
                    ? [ROLE_COMPANY_ADMINISTRATOR]
                    : [ROLE_CLIENT_USER],
                isActive: STATUS_ACTIVE,
                _links: !props.clientResponse
                    ? {}
                    : {
                          client: props.clientResponse._links.self,
                      },
            },
            id: props.match.params.id,
            isLoading: false,
            isClient: props.location.pathname.includes('client'),
            clientId: props.match.params.clientId,
            response: {},
        };

        this.load = this.load.bind(this);
        this.history = this.props.history;
        this.match = this.props.match;
    }

    componentDidMount() {
        this.load();
    }

    handleSubmit(event) {
        event.stopPropagation();
        event.preventDefault();

        if (this.state.id) {
            HTTP.put(
                Response.getLink(this.state.response, 'edit'),
                Object.assign({}, this.state.form)
            )
                .then((response) => {
                    if (response) {
                        toast.success('User updated');
                        Routing.navigateUpOneLevel(this.history, this.match);

                        return;
                    }

                    toast.error('Unable to update User');
                    return false;
                })
                .catch((error) => {
                    let response = error.response;

                    if (
                        response &&
                        response.data &&
                        response.data.length &&
                        response.data[0].hasOwnProperty('message')
                    ) {
                        toast.error(response.data[0].message);
                        return false;
                    }
                    toast.error(error.message);

                    return false;
                });

            return;
        }

        HTTP.post('/users/new', Object.assign({}, this.state.form))
            .then((response) => {
                if (response) {
                    toast.success('User created');
                    Routing.navigateUpOneLevel(this.history, this.match);

                    return;
                }

                toast.error('Unable to create User');
                return false;
            })
            .catch((error) => {
                let response = error.response;

                if (
                    response &&
                    response.data &&
                    response.data.length &&
                    response.data[0].hasOwnProperty('message')
                ) {
                    toast.error(response.data[0].message);
                    return false;
                }
                toast.error(error.message);

                return false;
            });
    }

    render() {
        const isEditing = typeof this.state.id !== 'undefined';
        const { form, isLoading } = this.state;

        return (
            <div className="UserAddEdit">
                <H1 className="clearfix">
                    {isEditing ? 'Edit User' : 'Add new User'}
                </H1>

                <Loading isLoading={isLoading}>
                    <form
                        className="AddEdit"
                        onSubmit={(event) => this.handleSubmit(event)}
                    >
                        <FormGroup
                            label="Forename"
                            inline={true}
                            className="form-fill required"
                            labelFor="forename"
                        >
                            <InputGroup
                                id="forename"
                                placeholder="Forename"
                                minLength={1}
                                maxLength={75}
                                value={this.state.form.forename}
                                onChange={(e) =>
                                    this.setState({
                                        form: StateHandler.getStateObject(
                                            e,
                                            this.state.form
                                        ),
                                    })
                                }
                                required
                            />
                        </FormGroup>
                        <FormGroup
                            label="Surname"
                            inline={true}
                            className="form-fill required"
                            labelFor="surname"
                        >
                            <InputGroup
                                id="surname"
                                placeholder="Surname"
                                minLength={1}
                                maxLength={75}
                                value={this.state.form.surname}
                                onChange={(e) =>
                                    this.setState({
                                        form: StateHandler.getStateObject(
                                            e,
                                            this.state.form
                                        ),
                                    })
                                }
                                required
                            />
                        </FormGroup>
                        <FormGroup
                            label="Job Title"
                            inline={true}
                            className="form-fill"
                            labelFor="surname"
                        >
                            <InputGroup
                                id="jobTitle"
                                placeholder="Job Title"
                                minLength={1}
                                maxLength={75}
                                value={this.state.form.jobTitle}
                                onChange={(e) =>
                                    this.setState({
                                        form: StateHandler.getStateObject(
                                            e,
                                            this.state.form
                                        ),
                                    })
                                }
                            />
                        </FormGroup>
                        <FormGroup
                            label="Email Address"
                            inline={true}
                            className="form-fill required"
                            labelFor="email"
                        >
                            <InputGroup
                                id="email"
                                type="email"
                                placeholder="Email Address"
                                minLength={1}
                                maxLength={75}
                                value={this.state.form.email}
                                onChange={(e) =>
                                    this.setState({
                                        form: StateHandler.getStateObject(
                                            e,
                                            this.state.form
                                        ),
                                    })
                                }
                                required
                            />
                        </FormGroup>
                        <FormGroup
                            label="Telephone"
                            inline={true}
                            className="form-fill"
                            labelFor="phone"
                        >
                            <InputGroup
                                id="phone"
                                placeholder="Telephone"
                                type="number"
                                minLength={1}
                                maxLength={75}
                                value={this.state.form.phone}
                                onChange={(e) =>
                                    this.setState({
                                        form: StateHandler.getStateObject(
                                            e,
                                            this.state.form
                                        ),
                                    })
                                }
                            />
                        </FormGroup>
                        {isEditing ? null : (
                            <FormGroup
                                label="Password"
                                inline={true}
                                className="form-fill required"
                                labelFor="password"
                            >
                                <PasswordField
                                    id="password"
                                    autoComplete="new-password"
                                    value={this.state.form.password}
                                    onChange={(e) =>
                                        this.setState({
                                            form: StateHandler.getStateObject(
                                                e,
                                                this.state.form
                                            ),
                                        })
                                    }
                                    required
                                />
                            </FormGroup>
                        )}

                        {canNavigateWithRestriction([ROLE_ADMINISTRATOR]) ? (
                            <FormGroup
                                label="Role"
                                inline={true}
                                className="form-fill"
                                labelFor="roles"
                            >
                                <HTMLSelect
                                    id="roles"
                                    fill={true}
                                    defaultValue={this.state.form.roles[0]}
                                    onChange={(e) =>
                                        this.setState({
                                            form: StateHandler.getStateObject(
                                                e,
                                                this.state.form
                                            ),
                                        })
                                    }
                                    options={
                                        this.state.isClient
                                            ? CLIENT_ROLE_OPTIONS
                                            : ROLE_OPTIONS_WITHOUT_CLIENT
                                    }
                                    disabled={
                                        !this.state.isClient &&
                                        this.state.id &&
                                        this.state.form.roles.some((role) =>
                                            CLIENT_ROLES.includes(role)
                                        )
                                    }
                                />
                            </FormGroup>
                        ) : null}

                        <FormGroup
                            label="Status"
                            inline={true}
                            className="form-fill"
                            labelFor="isActive"
                        >
                            <HTMLSelect
                                id="isActive"
                                fill={true}
                                defaultValue={form.isActive.toString()}
                                onChange={(e) =>
                                    this.setState({
                                        form: StateHandler.getStateObject(
                                            e,
                                            this.state.form
                                        ),
                                    })
                                }
                                options={USER_STATUS_TRANSLATION}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Button
                                onClick={() =>
                                    Routing.navigateUpOneLevel(
                                        this.history,
                                        this.match
                                    )
                                }
                                type="button"
                                intent="default"
                                className="float-left"
                            >
                                <Icon icon="ban" />
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                intent="primary"
                                className="float-right"
                            >
                                <Icon icon="paper-plane" />
                                {isEditing ? 'Update' : 'Create'}
                            </Button>
                        </FormGroup>
                    </form>
                </Loading>
            </div>
        );
    }

    loadUser(response) {
        const formData = response.data;
        formData.roles = formData.roles.filter((role) => role !== 'ROLE_USER');

        this.setState({
            isLoading: false,
            response: response.data,
            form: Object.assign({}, formData),
        });

        return true;
    }

    load() {
        if (this.state.loading || typeof this.state.id === 'undefined') {
            return;
        }

        this.setState({ isLoading: true });

        HTTP.get(`/users/${this.state.id}`).then((response) => {
            if (response) {
                this.loadUser(response);

                return true;
            }

            toast.error('Unable to fetch User details');
            this.setState({ isLoading: false });

            return false;
        });
    }
}

export default withRouter(UserAddEdit);

import { Popover2, Tooltip2 } from '@blueprintjs/popover2';
import { useState } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Button, Menu, MenuItem, PopoverPosition } from '@blueprintjs/core';
import AssignInvitee from 'components/dialogs/AssignInvitee';
import SendCampaignEmail from 'components/dialogs/SendCampaignEmail';
import UpdateInviteeProgress from 'components/dialogs/UpdateInviteeProgress';
import { ButtonLink, Icon, List } from 'components/elements';
import { YES_NO_OPTIONS } from 'constants/general';
import { NONE_SELECTED_OPTION } from 'constants/none-selected';
import { delay, parseDateFormat, toMoney } from 'helpers/helpers';
import { ListCollection } from 'interface';
import {
    CampaignComponentProps,
    CampaignInvitee,
    CampaignInviteeListFilter,
    CampaignPriorityMapping,
    CampaignPriorityOptions,
    InviteeUpdateFollowUpDateOptions,
    InviteeUpdateStatusOptions,
} from 'interface/Client/Campaign';

import { Response, Routing } from 'service';
import CampaignService from 'service/Client/CampaignService';
import ServiceProviderCategoryPopup from './ServiceProviderCategoryPopup/ServiceProviderCategoryPopup';
import InviteeUpdateInformation from './invitee/InviteeUpdateInformation';

import './index.scss';

const CampaignInviteeTracker = (props: CampaignComponentProps) => {
    const { match, campaign, location, history } = props;
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [collection, setCollection] = useState<
        ListCollection<CampaignInvitee>
    >({} as ListCollection<CampaignInvitee>);

    const defaultFilters = {
        order: 'inviteeName',
        ...Routing.parseSearchParams(location.search),
    };

    const [listFilter, setListFilter] = useState<CampaignInviteeListFilter>({
        ...defaultFilters,
    } as CampaignInviteeListFilter);

    const searchFilters = {
        inviteeName: {
            type: 'text',
            label: 'Invitee Name',
            key: 'inviteeName',
            placeholder: 'e.g. John Smith Industries',
        },
        priority: {
            type: 'single-select',
            defaultValue: NONE_SELECTED_OPTION,
            label: 'Priority for Client',
            key: 'priority',
            options: [NONE_SELECTED_OPTION, ...CampaignPriorityOptions],
        },
        companyName: {
            type: 'text',
            label: 'Service Provider Name',
            key: 'companyName',
            placeholder: 'e.g. John Smith Industries',
        },
        hasLinked: {
            type: 'single-select',
            defaultValue: NONE_SELECTED_OPTION,
            label: 'Joined Prosure',
            key: 'hasLinked',
            options: [NONE_SELECTED_OPTION, ...YES_NO_OPTIONS],
        },
        hasJoinedSupplyChain: {
            type: 'single-select',
            defaultValue: NONE_SELECTED_OPTION,
            label: 'Joined Supply Chain',
            key: 'hasJoinedSupplyChain',
            options: [NONE_SELECTED_OPTION, ...YES_NO_OPTIONS],
        },
        withUpdates: {
            type: 'single-select',
            defaultValue: NONE_SELECTED_OPTION,
            label: 'Updates / Status',
            key: 'withUpdates',
            options: InviteeUpdateStatusOptions,
        },
        hasFollowUpDate: {
            type: 'single-select',
            defaultValue: NONE_SELECTED_OPTION,
            label: 'Follow Up Date',
            key: 'hasFollowUpDate',
            options: InviteeUpdateFollowUpDateOptions,
        },
    };

    const [isAssignOpen, setIsAssignOpen] = useState<boolean>(false);
    const [isSelectAll, setIsSelectAll] = useState<boolean>(false);
    const [isProgressOpen, setIsProgressOpen] = useState<boolean>(false);
    const [isSendEmailOpen, setIsSendEmailOpen] = useState<boolean>(false);
    const [currentInvitee, setCurrentInvitee] = useState<CampaignInvitee>();
    const [selectedInvitees, setSelectedInvitees] =
        useState<CampaignInvitee[]>();

    const load = async (filter: CampaignInviteeListFilter) => {
        setIsLoading(true);
        setSelectedInvitees(undefined);
        setCollection(await CampaignService.loadInviteeList(campaign, filter));
        setListFilter({ ...defaultFilters, ...filter });
        await delay(0);
        setIsLoading(false);
    };

    const downloadHandler = async (filter: CampaignInviteeListFilter) => {
        await CampaignService.downloadCampaignInviteeTrackerList(
            campaign?.id,
            filter
        );

        toast.success('Invitee Tracker List Downloaded');
    };

    const showUpdateMessage = (invitee: CampaignInvitee) => (
        <InviteeUpdateInformation
            invitee={invitee}
            onClick={clickUpdateButton}
        />
    );

    const renderContactName = (invitee: CampaignInvitee) => {
        const { forename, surname } = invitee;

        if (!forename && !surname) {
            return null;
        }

        return (
            <>
                <span>{`${forename || ''} ${surname || ''}`}</span>
                <br />
            </>
        );
    };

    const renderContactEmail = (invitee: CampaignInvitee) => {
        const { email } = invitee;

        if (!email) {
            return null;
        }

        return (
            <>
                <span>{email}</span>
                <br />
            </>
        );
    };

    const renderContactPhone = (invitee: CampaignInvitee) => {
        const { telephone } = invitee;

        if (!telephone) {
            return null;
        }

        return <span>{telephone}</span>;
    };

    const declinedSpan = <span className="error">Declined</span>;

    const columns = [
        {
            name: 'Client Name',
            property: 'clientName',
            type: 'callback',
            sortable: true,
            grow: 3,
            hide: Boolean(campaign),
            callback: (invitee: CampaignInvitee) =>
                Response.getLinkAttribute(invitee, 'client', 'name'),
        },
        {
            name: 'Campaign Name',
            property: 'campaignName',
            sortable: true,
            grow: 3,
            hide: Boolean(campaign),
        },
        {
            property: 'sendEmail',
            type: 'checkbox',
            grow: 1,
            onChange: (selection: Object) => {
                setSelectedInvitees(Object.values(selection));
            },
            hide: Boolean(!campaign), // hide for multiple-campaign invitees list since email popup requires a campaign template
        },
        {
            name: 'Invitee Name',
            property: 'inviteeName',
            sortable: true,
            grow: 3,
        },
        {
            name: 'Contact Details',
            grow: 4,
            type: 'callback',
            callback: (invitee: CampaignInvitee) => {
                const content = (
                    <div>
                        {renderContactName(invitee)}
                        {renderContactEmail(invitee)}
                        {renderContactPhone(invitee)}
                    </div>
                );

                return (
                    <>
                        <Tooltip2
                            content={content}
                            position={PopoverPosition.TOP}
                            className="ms-2 contact-tooltip"
                        >
                            {content}
                        </Tooltip2>
                    </>
                );
            },
        },
        {
            name: 'Service Provider Category',
            property: 'spCategoryName',
            sortable: false,
            grow: 3,
            hide: Boolean(!campaign),
            type: 'callback',
            callback: (invitee: CampaignInvitee) => (
                <ServiceProviderCategoryPopup invitee={invitee} />
            ),
        },
        {
            name: 'Priority For Client',
            property: 'priority',
            type: 'mapping',
            mapping: CampaignPriorityMapping,
            sortable: true,
            grow: 3,
        },
        {
            name: 'Joined Prosure',
            property: 'joinedProsure',
            type: 'callback',
            sortable: true,
            grow: 3,
            callback: (row: CampaignInvitee) => {
                if (row.statusIsDeclined) {
                    return declinedSpan;
                }

                return row.linkedAt ? parseDateFormat(row.linkedAt) : 'No';
            },
        },
        {
            name: 'Service Provider Name',
            type: 'callback',
            property: 'serviceProviderName',
            sortable: true,
            grow: 3,
            callback: (row: CampaignInvitee) => {
                if (row.statusIsDeclined) {
                    return declinedSpan;
                }

                return row.companyName ? (
                    <Link
                        to={
                            '/service-providers/' +
                            Response.getLinkAttribute(row, 'company', 'id')
                        }
                    >
                        {row.companyName}
                    </Link>
                ) : (
                    'TBC'
                );
            },
        },
        {
            name: 'Total Spend',
            property: 'totalSpend',
            sortable: true,
            type: 'callback',
            grow: 3,
            callback: (row: CampaignInvitee) => {
                const companyId = Response.getLinkAttribute(
                    row,
                    'company',
                    'id'
                );

                if (!row.companyName || !companyId) {
                    return 'TBC';
                }

                return (
                    <Link
                        to={`/service-providers/${companyId}/all-assessments`}
                    >
                        {toMoney(row.totalSpend, false)}
                    </Link>
                );
            },
        },
        {
            name: 'Joined Supply Chain',
            type: 'callback',
            property: 'joinedSupplyChain',
            sortable: true,
            grow: 3,
            callback: (row: CampaignInvitee) => {
                if (row.statusIsDeclined) {
                    return declinedSpan;
                }

                if (typeof row.companyName === 'undefined') {
                    return 'TBC';
                }

                return row.joinedSupplyChainAt ? (
                    <>
                        {parseDateFormat(row.joinedSupplyChainAt, 'DD/MM/YYYY')}
                    </>
                ) : (
                    'No'
                );
            },
        },
        {
            name: 'Update/Status',
            property: 'latestStatusUpdate',
            type: 'callback',
            callback: showUpdateMessage,
            sortable: true,
            grow: 5,
        },
        {
            name: 'Follow Up Date',
            property: 'latestFollowUpDate',
            type: 'datetime',
            sortable: true,
            grow: 2,
        },
        {
            name: '',
            type: 'callback',
            grow: 2,
            callback: (row: CampaignInvitee) => {
                return (
                    <Popover2
                        className="inline-block"
                        content={actionsMenu(row)}
                        placement="bottom"
                    >
                        <Button
                            alignText="left"
                            rightIcon="caret-down"
                            text="Actions"
                        />
                    </Popover2>
                );
            },
        },
    ];

    const getHeaderButton = () => {
        if (!campaign) {
            return null;
        }

        return (
            <div className="float-right">
                <ButtonLink
                    type="button"
                    to={`${match.url}/add-existing-sp`}
                    intent="primary"
                    className="me-2"
                >
                    <Icon icon="plus-circle" />
                    Add Existing Service Provider
                </ButtonLink>
                <ButtonLink
                    type="button"
                    to={`${match.url}/add`}
                    intent="primary"
                >
                    <Icon icon="plus-circle" />
                    Add Invitee
                </ButtonLink>
            </div>
        );
    };

    const iconButtons = () => {
        if (!selectedInvitees?.length) {
            return null;
        }

        return (
            <>
                <Tooltip2
                    content="Send Emails"
                    position={PopoverPosition.TOP}
                    className="ms-2"
                >
                    <button
                        className="clear-button"
                        onClick={(event) => {
                            setIsSendEmailOpen(true);
                        }}
                    >
                        <Icon icon="envelope" />
                    </button>
                </Tooltip2>
            </>
        );
    };

    const selectAll = () => {
        setIsSendEmailOpen(true);
        setIsSelectAll(true);
    };

    const actionsMenu = (row: CampaignInvitee) => {
        let inviteeId = row.id;
        let campaignId = Response.getLinkAttribute(row, 'campaign', 'id');
        let inviteeEditLink = `/clients/campaigns/list/${campaignId}/invitees/${inviteeId}/edit`;

        return (
            <Menu>
                <MenuItem
                    intent="danger"
                    text="Edit Invitee Details"
                    icon={<Icon className="mt-1" icon="pencil" />}
                    onClick={() => history.push(inviteeEditLink)}
                />
                {Response.getLink(row, 'assign') && (
                    <MenuItem
                        intent="warning"
                        text="Link to Existing Service Provider"
                        icon="link"
                        onClick={(event) => {
                            clickAssignButton(event, row);
                        }}
                    />
                )}
                {row.email?.length > 0 && (
                    <MenuItem
                        intent="success"
                        text="Trigger Email"
                        icon={<Icon className="mt-1" icon="envelope" />}
                        onClick={(event) => {
                            clickSendEmailButton(event as any, row);
                        }}
                    />
                )}
                {Response.getLink(row, 'update-status') && (
                    <MenuItem
                        intent="primary"
                        text="Update Status"
                        icon={<Icon className="mt-1" icon="user-check" />}
                        onClick={(event) => {
                            clickUpdateButton(event as any, row);
                        }}
                    />
                )}
            </Menu>
        );
    };

    const clickUpdateButton = async (event: any, invitee: CampaignInvitee) => {
        event.preventDefault();
        event.stopPropagation();
        setIsProgressOpen(true);
        setCurrentInvitee(invitee);
    };

    const clickAssignButton = async (event: any, invitee: CampaignInvitee) => {
        event.preventDefault();
        event.stopPropagation();
        setIsAssignOpen(true);
        setCurrentInvitee(invitee);
    };

    const clickSendEmailButton = async (
        event: any,
        invitee: CampaignInvitee
    ) => {
        event.preventDefault();
        event.stopPropagation();
        setIsSendEmailOpen(true);
        setCurrentInvitee(invitee);
    };

    return (
        <>
            {(!campaign || (campaign && campaign.id)) && (
                <div className="CampaignInviteeList">
                    <List
                        title="Invitee Tracker"
                        columns={columns}
                        load={(filter: CampaignInviteeListFilter) =>
                            load(filter)
                        }
                        collection={collection}
                        filter={listFilter}
                        filters={searchFilters}
                        isLoading={isLoading}
                        noRecordsFoundText="There are no campaign invitees"
                        headerButton={getHeaderButton}
                        downloadHandler={downloadHandler}
                        iconButtons={iconButtons}
                        selectAll={selectAll}
                    />
                </div>
            )}
            <AssignInvitee
                isOpen={isAssignOpen}
                onClose={() => {
                    setIsAssignOpen(false);
                    load(listFilter);
                }}
                invitee={currentInvitee}
            />
            <UpdateInviteeProgress
                invitee={currentInvitee}
                isOpen={isProgressOpen}
                onClose={() => {
                    setIsProgressOpen(false);
                    load(listFilter);
                }}
            />
            <SendCampaignEmail
                invitee={currentInvitee as CampaignInvitee}
                invitees={selectedInvitees}
                isSendAll={isSelectAll}
                campaign={campaign}
                isOpen={isSendEmailOpen}
                filter={listFilter}
                onClose={() => {
                    setIsSelectAll(false);
                    setIsSendEmailOpen(false);
                    load(listFilter);
                }}
            />
        </>
    );
};

export default withRouter(CampaignInviteeTracker);

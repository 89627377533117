import { H2 } from '@blueprintjs/core';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import DashboardCard from 'components/dashboard/DashboardCard';
import DashboardCompanyCountsCard from 'components/dashboard/DashboardCompanyCountsCard';
import { Loading } from 'components/elements/wrappers';
import { HTTP } from 'service';

class AdministratorDashboard extends Component {
    loadInterval;

    constructor(props) {
        super(props);

        this.state = {
            counts: {
                clientCount: 0,
                dashboardCounts: {},
            },
            isLoading: false,
            isLoadingServiceProviders: false,
            isLoadingClients: false,
            isLoadingAssessments: false,
        };

        this.loadAll = this.loadAll.bind(this);
        this.loadClients = this.loadClients.bind(this);
        this.loadAssessments = this.loadAssessments.bind(this);
    }

    componentDidMount() {
        this.loadAll();
    }

    componentWillUnmount() {
        window.clearInterval(this.loadInterval);
    }

    renderAssessmentsInProgress = () => {
        const { dashboardCounts } = this.state.counts;

        return (
            <ul className="supply-chain-rating">
                <li className="rating-row">
                    <Link
                        className="no-decoration"
                        to="/review/assessments?currentStatus=status_submitted_awaiting_assessor"
                    >
                        <H2 className="rating">
                            {dashboardCounts.awaitingAssessorCount}
                        </H2>
                        <span className="rating-text">Awaiting Assessor</span>
                    </Link>
                </li>
                <li className="rating-row">
                    <Link
                        className="no-decoration"
                        to="/review/assessments?currentStatus=status_instance_assigned_to_assessor"
                    >
                        <H2 className="rating">
                            {dashboardCounts.beingAssessedCount}
                        </H2>
                        <span className="rating-text">Being Assessed</span>
                    </Link>
                </li>
                <li className="rating-row">
                    <Link
                        className="no-decoration"
                        to="/review/assessments?currentStatus=status_submitted_returned"
                    >
                        <H2 className="rating">
                            {dashboardCounts.returnedCount}
                        </H2>
                        <span className="rating-text">
                            Returned with Feedback
                        </span>
                    </Link>
                </li>
                <li className="rating-row">
                    <Link
                        className="no-decoration"
                        to="/review/assessments?currentStatus=status_pending_awaiting_reviewer"
                    >
                        <H2 className="rating">
                            {dashboardCounts.awaitingReviewerCount}
                        </H2>
                        <span className="rating-text">Awaiting Reviewer</span>
                    </Link>
                </li>
                <li className="rating-row ">
                    <Link
                        className="no-decoration"
                        to="/review/assessments?currentStatus=status_instance_assigned_to_reviewer"
                    >
                        <H2 className="rating">
                            {dashboardCounts.beingReviewedCount}
                        </H2>
                        <span className="rating-text">Being Reviewed</span>
                    </Link>
                </li>
            </ul>
        );
    };

    render() {
        const { counts, isLoading, isLoadingClients, isLoadingAssessments } =
            this.state;

        return (
            <div className={'DashboardPage client'}>
                <Loading isLoading={isLoading}>
                    <DashboardCompanyCountsCard
                        counts={counts.dashboardCounts}
                        isLoading={isLoadingAssessments}
                    />

                    <DashboardCard
                        isLoading={isLoadingClients}
                        title="Active Clients"
                        moreText="Go to Clients..."
                        link="/clients/list"
                        value={counts.clientCount}
                    />

                    <DashboardCard
                        isLoading={isLoadingAssessments}
                        title="Assessments In-Progress "
                        moreText="Go to Review/Assess..."
                        link="/review/assessments"
                        value={counts.dashboardCounts.totalInProgress}
                    />

                    <DashboardCard
                        isFullWidth
                        isLoading={isLoadingAssessments}
                        title="Assessments In-Progress"
                        moreText="Go to Review/Assess..."
                        moreDetailsLink="/review/assessments"
                    >
                        {this.renderAssessmentsInProgress()}
                    </DashboardCard>
                </Loading>
            </div>
        );
    }

    loadAll() {
        this.setState({ isLoading: true });
        this.loadClients();
        this.loadAssessments();
        this.setState({ isLoading: false });
    }

    loadClients() {
        if (this.state.isLoadingClients) {
            return;
        }

        this.setState({ isLoadingClients: true });

        HTTP.get(`/clients?isActive=true&limit=1`).then((response) => {
            if (response) {
                let updatedCounts = {
                    ...this.state.counts,
                    clientCount: response.data.count,
                };

                this.setState({
                    ...this.state,
                    counts: updatedCounts,
                    isLoadingClients: false,
                });

                return true;
            }

            toast.error('Unable to fetch Clients');
            this.setState({ isLoadingClients: false });

            return false;
        });
    }

    loadAssessments() {
        if (this.state.isLoadingAssessments) {
            return;
        }

        this.setState({ isLoadingAssessments: true });

        HTTP.get(`/admin/dashboard`).then((response) => {
            if (response) {
                let updatedCounts = {
                    ...this.state.counts,
                    dashboardCounts: response.data,
                };

                this.setState({
                    ...this.state,
                    counts: updatedCounts,
                    isLoadingAssessments: false,
                });

                return true;
            }

            toast.error('Unable to fetch Admin Dashboard');
            this.setState({ isLoadingAssessments: false });

            return false;
        });
    }
}

export default AdministratorDashboard;

import { Button, FormGroup, H1, H2, InputGroup } from '@blueprintjs/core';
import {
    ButtonLink,
    HTMLSelect,
    Icon,
    InputElementIcon,
} from 'components/elements';
import FileSingleUpload from 'components/elements/file-upload/FileSingleUpload';
import {
    ORGANISATION_TYPE_OPTIONS,
    ORGANISATION_TYPE_PROPERTY_MANAGEMENT,
} from 'constants/client';
import { MAX_FILE_UPLOAD_SIZE_MB } from 'constants/general';
import { merge } from 'lodash';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import { HTTP, Response, Routing, StateHandler } from 'service';

import { COUNTRY_CODE_OPTIONS } from 'constants/countries';

class ClientAddEdit extends Component {
    static propTypes = {
        clientResponse: PropTypes.object,
    };
    static defaultProps = {};

    constructor(props) {
        super(props);

        this.state = {
            form: {
                name: '',
                phone: '',
                organisationType: ORGANISATION_TYPE_PROPERTY_MANAGEMENT,
                customerCode: '',
                isActive: 'true',
                registeredAddress: {
                    addressLine1: '',
                    addressLine2: '',
                    addressLine3: '',
                    town: '',
                    county: '',
                    postCode: '',
                    country: 'GB',
                },
                invoiceAddress: {
                    addressLine1: '',
                    addressLine2: '',
                    addressLine3: '',
                    town: '',
                    county: '',
                    postCode: '',
                    country: 'GB',
                },
                _links: {
                    logo: null,
                },
                ...(props.clientResponse || {}),
            },
            clientId: props.clientResponse
                ? props.clientResponse.id
                : undefined,
        };
    }

    handleSubmit(event) {
        event.stopPropagation();
        event.preventDefault();

        const { clientResponse, history, match } = this.props;
        const { form } = this.state;

        if (typeof clientResponse === 'object') {
            return HTTP.put(
                Response.getLink(clientResponse, 'edit'),
                form
            ).then(
                (response) => {
                    if (response) {
                        toast.success('Client updated');
                        Routing.navigateUpOneLevel(history, match);

                        if (this.props.mergeClientResponse) {
                            this.props.mergeClientResponse(form);
                        }

                        return true;
                    }

                    toast.error('Unable to update Client');

                    return false;
                },
                () => {
                    toast.error('Unable to update Client');

                    return false;
                }
            );
        }

        HTTP.post('/clients/new', form).then(
            (response) => {
                if (response) {
                    toast.success('Client created');

                    Routing.navigateUpOneLevel(
                        history,
                        match,
                        response.data.id
                    );

                    return true;
                }

                toast.error('Unable to create Client');

                return false;
            },
            () => {
                toast.error('Unable to create Client');

                return false;
            }
        );
    }

    onUpdate = (fileJson) => {
        const result = merge({}, this.state.form, fileJson);
        this.setState({ form: result });
    };

    render() {
        const isEditing = typeof this.state.clientId !== 'undefined';
        const { form } = this.state;
        const fileLabel = (
            <>
                Company Logo
                {InputElementIcon(
                    `Maximum 250x250px. Max file size ${MAX_FILE_UPLOAD_SIZE_MB}MB`,
                    'info'
                )}
            </>
        );

        return (
            <div className="ClientAddEdit">
                <H1>{isEditing ? 'Edit Client' : 'Add new Client'}</H1>

                <form
                    className="AddEdit"
                    onSubmit={(event) => this.handleSubmit(event)}
                >
                    <H2 className="clearfix">Client Information</H2>

                    <FormGroup
                        label="Name"
                        inline={true}
                        className="form-fill required"
                        labelFor="name"
                    >
                        <InputGroup
                            id="name"
                            placeholder="Name"
                            minLength={1}
                            maxLength={75}
                            value={form.name}
                            onChange={(e) =>
                                this.setState({
                                    form: StateHandler.getStateObject(e, form),
                                })
                            }
                            required
                        />
                    </FormGroup>

                    <FormGroup
                        label="Trading Name"
                        inline={true}
                        className="form-fill"
                        labelFor="tradingName"
                    >
                        <InputGroup
                            id="tradingName"
                            placeholder="Trading Name"
                            minLength={1}
                            maxLength={75}
                            value={form.tradingName}
                            onChange={(e) =>
                                this.setState({
                                    form: StateHandler.getStateObject(e, form),
                                })
                            }
                        />
                    </FormGroup>

                    <FormGroup
                        label="Phone Number"
                        inline={true}
                        className="form-fill"
                        labelFor="phone"
                    >
                        <InputGroup
                            id="phone"
                            fill={true}
                            type="phone"
                            placeholder="Phone Number"
                            value={form.phone}
                            onChange={(e) =>
                                this.setState({
                                    form: StateHandler.getStateObject(e, form),
                                })
                            }
                            rightElement={InputElementIcon(
                                'Enter the phone number',
                                'info'
                            )}
                        />
                    </FormGroup>

                    <FormGroup
                        label="Type of Organisation"
                        inline={true}
                        className="form-fill required"
                        labelFor="organisationType"
                    >
                        <HTMLSelect
                            id="organisationType"
                            fill={true}
                            defaultValue={form.organisationType}
                            onChange={(e) =>
                                this.setState({
                                    form: StateHandler.getStateObject(
                                        e,
                                        this.state.form
                                    ),
                                })
                            }
                            options={ORGANISATION_TYPE_OPTIONS}
                            required
                        />
                    </FormGroup>

                    <FormGroup
                        label="Customer Code"
                        inline={true}
                        className="form-fill required"
                        labelFor="customerCode"
                    >
                        <InputGroup
                            id="customerCode"
                            placeholder="Customer Code"
                            minLength={3}
                            maxLength={8}
                            value={form.customerCode}
                            onChange={(e) =>
                                this.setState({
                                    form: StateHandler.getStateObject(e, form),
                                })
                            }
                            required
                        />
                    </FormGroup>

                    <FileSingleUpload
                        form={form}
                        fileId="logo"
                        isImage={true}
                        isMobile={isMobile}
                        fileLabel={fileLabel}
                        onUpdate={this.onUpdate}
                    ></FileSingleUpload>

                    <H2 className="clearfix">Registered Address</H2>

                    <FormGroup
                        label="Address Line 1"
                        inline={true}
                        className="form-fill required"
                        labelFor="registeredAddress.addressLine1"
                    >
                        <InputGroup
                            id="registeredAddress.addressLine1"
                            fill={true}
                            required
                            placeholder="Address Line 1"
                            value={form.registeredAddress.addressLine1}
                            onChange={(e) =>
                                this.setState({
                                    ...this.state,
                                    form: {
                                        ...this.state.form,
                                        registeredAddress: {
                                            ...this.state.form
                                                .registeredAddress,
                                            addressLine1: e.target.value,
                                        },
                                    },
                                })
                            }
                        />
                    </FormGroup>
                    <FormGroup
                        label="Address Line 2"
                        inline={true}
                        className="form-fill"
                        labelFor="addressLine2"
                    >
                        <InputGroup
                            id="addressLine2"
                            fill={true}
                            placeholder="Address Line 2"
                            value={form.registeredAddress.addressLine2}
                            onChange={(e) =>
                                this.setState({
                                    ...this.state,
                                    form: {
                                        ...this.state.form,
                                        registeredAddress: {
                                            ...this.state.form
                                                .registeredAddress,
                                            addressLine2: e.target.value,
                                        },
                                    },
                                })
                            }
                        />
                    </FormGroup>
                    <FormGroup
                        label="Address Line 3"
                        inline={true}
                        className="form-fill"
                        labelFor="addressLine3"
                    >
                        <InputGroup
                            id="addressLine3"
                            fill={true}
                            placeholder="Address Line 3"
                            value={form.registeredAddress.addressLine3}
                            onChange={(e) =>
                                this.setState({
                                    ...this.state,
                                    form: {
                                        ...this.state.form,
                                        registeredAddress: {
                                            ...this.state.form
                                                .registeredAddress,
                                            addressLine3: e.target.value,
                                        },
                                    },
                                })
                            }
                        />
                    </FormGroup>
                    <FormGroup
                        label="Town/City"
                        inline={true}
                        className="form-fill required"
                        labelFor="town"
                    >
                        <InputGroup
                            id="town"
                            fill={true}
                            required
                            placeholder="Town/City"
                            value={form.registeredAddress.town}
                            onChange={(e) =>
                                this.setState({
                                    ...this.state,
                                    form: {
                                        ...this.state.form,
                                        registeredAddress: {
                                            ...this.state.form
                                                .registeredAddress,
                                            town: e.target.value,
                                        },
                                    },
                                })
                            }
                        />
                    </FormGroup>
                    <FormGroup
                        label="County/Region"
                        inline={true}
                        className="form-fill"
                        labelFor="county"
                    >
                        <InputGroup
                            id="county"
                            fill={true}
                            placeholder="County/Region"
                            value={form.registeredAddress.county}
                            onChange={(e) =>
                                this.setState({
                                    ...this.state,
                                    form: {
                                        ...this.state.form,
                                        registeredAddress: {
                                            ...this.state.form
                                                .registeredAddress,
                                            county: e.target.value,
                                        },
                                    },
                                })
                            }
                        />
                    </FormGroup>
                    <FormGroup
                        label="Postal/Zip Code"
                        inline={true}
                        className="form-fill"
                        labelFor="postCode"
                    >
                        <InputGroup
                            id="postCode"
                            fill={true}
                            placeholder="Postal/Zip Code"
                            value={form.registeredAddress.postCode}
                            onChange={(e) =>
                                this.setState({
                                    ...this.state,
                                    form: {
                                        ...this.state.form,
                                        registeredAddress: {
                                            ...this.state.form
                                                .registeredAddress,
                                            postCode: e.target.value,
                                        },
                                    },
                                })
                            }
                        />
                    </FormGroup>

                    <FormGroup
                        inline={true}
                        label="Country"
                        labelFor="country"
                        className="form-fill required"
                    >
                        <HTMLSelect
                            id="country"
                            required
                            fill={true}
                            options={COUNTRY_CODE_OPTIONS}
                            defaultValue={form.registeredAddress.country}
                            onChange={(e) =>
                                this.setState({
                                    ...this.state,
                                    form: {
                                        ...this.state.form,
                                        registeredAddress: {
                                            ...this.state.form
                                                .registeredAddress,
                                            country: e.target.value,
                                        },
                                    },
                                })
                            }
                        />
                    </FormGroup>

                    <H2 className="clearfix">Invoice Address</H2>

                    <FormGroup
                        label="Address Line 1"
                        inline={true}
                        className="form-fill required"
                        labelFor="invoiceAddress.addressLine1"
                    >
                        <InputGroup
                            id="invoiceAddress.addressLine1"
                            fill={true}
                            required
                            placeholder="Address Line 1"
                            value={form.invoiceAddress.addressLine1}
                            onChange={(e) =>
                                this.setState({
                                    ...this.state,
                                    form: {
                                        ...this.state.form,
                                        invoiceAddress: {
                                            ...this.state.form.invoiceAddress,
                                            addressLine1: e.target.value,
                                        },
                                    },
                                })
                            }
                        />
                    </FormGroup>
                    <FormGroup
                        label="Address Line 2"
                        inline={true}
                        className="form-fill"
                        labelFor="invoiceAddress.addressLine2"
                    >
                        <InputGroup
                            id="invoiceAddress.addressLine2"
                            fill={true}
                            placeholder="Address Line 2"
                            value={form.invoiceAddress.addressLine2}
                            onChange={(e) =>
                                this.setState({
                                    ...this.state,
                                    form: {
                                        ...this.state.form,
                                        invoiceAddress: {
                                            ...this.state.form.invoiceAddress,
                                            addressLine2: e.target.value,
                                        },
                                    },
                                })
                            }
                        />
                    </FormGroup>
                    <FormGroup
                        label="Address Line 3"
                        inline={true}
                        className="form-fill"
                        labelFor="invoiceAddress.addressLine3"
                    >
                        <InputGroup
                            id="invoiceAddress.addressLine3"
                            fill={true}
                            placeholder="Address Line 3"
                            value={form.invoiceAddress.addressLine3}
                            onChange={(e) =>
                                this.setState({
                                    ...this.state,
                                    form: {
                                        ...this.state.form,
                                        invoiceAddress: {
                                            ...this.state.form.invoiceAddress,
                                            addressLine3: e.target.value,
                                        },
                                    },
                                })
                            }
                        />
                    </FormGroup>
                    <FormGroup
                        label="Town/City"
                        inline={true}
                        className="form-fill required"
                        labelFor="invoiceAddress.town"
                    >
                        <InputGroup
                            id="invoiceAddress.town"
                            fill={true}
                            required
                            placeholder="Town/City"
                            value={form.invoiceAddress.town}
                            onChange={(e) =>
                                this.setState({
                                    ...this.state,
                                    form: {
                                        ...this.state.form,
                                        invoiceAddress: {
                                            ...this.state.form.invoiceAddress,
                                            town: e.target.value,
                                        },
                                    },
                                })
                            }
                        />
                    </FormGroup>
                    <FormGroup
                        label="County/Region"
                        inline={true}
                        className="form-fill"
                        labelFor="invoiceAddress.county"
                    >
                        <InputGroup
                            id="invoiceAddress.county"
                            fill={true}
                            placeholder="County/Regiom"
                            value={form.invoiceAddress.county}
                            onChange={(e) =>
                                this.setState({
                                    ...this.state,
                                    form: {
                                        ...this.state.form,
                                        invoiceAddress: {
                                            ...this.state.form.invoiceAddress,
                                            county: e.target.value,
                                        },
                                    },
                                })
                            }
                        />
                    </FormGroup>
                    <FormGroup
                        label="Postal/Zip Code"
                        inline={true}
                        className="form-fill"
                        labelFor="invoiceAddress.postCode"
                    >
                        <InputGroup
                            id="invoiceAddress.postCode"
                            fill={true}
                            placeholder="Postal/Zip Code"
                            value={form.invoiceAddress.postCode}
                            onChange={(e) =>
                                this.setState({
                                    ...this.state,
                                    form: {
                                        ...this.state.form,
                                        invoiceAddress: {
                                            ...this.state.form.invoiceAddress,
                                            postCode: e.target.value,
                                        },
                                    },
                                })
                            }
                        />
                    </FormGroup>

                    <FormGroup
                        inline={true}
                        label="Country"
                        labelFor="invoiceAddress.country"
                        className="form-fill required"
                    >
                        <HTMLSelect
                            id="invoiceAddress.country"
                            required
                            fill={true}
                            options={COUNTRY_CODE_OPTIONS}
                            defaultValue={form.invoiceAddress.country}
                            onChange={(e) =>
                                this.setState({
                                    ...this.state,
                                    form: {
                                        ...this.state.form,
                                        invoiceAddress: {
                                            ...this.state.form.invoiceAddress,
                                            country: e.target.value,
                                        },
                                    },
                                })
                            }
                        />
                    </FormGroup>

                    <FormGroup>
                        <ButtonLink
                            type="button"
                            intent="default"
                            className="float-left"
                            to={
                                isEditing
                                    ? `/clients/${this.state.clientId}/info`
                                    : this.state.isActive === 'true'
                                    ? '/clients/active-list'
                                    : '/clients/archived-list'
                            }
                        >
                            <Icon icon="ban" />
                            Cancel
                        </ButtonLink>
                        <Button
                            type="submit"
                            intent="primary"
                            className="float-right"
                        >
                            <Icon icon="paper-plane" />
                            {isEditing ? 'Update' : 'Create'}
                        </Button>
                    </FormGroup>
                </form>
            </div>
        );
    }
}

export default withRouter(ClientAddEdit);
